import { getLang } from '@/utils/lang';
import { createRequest } from '@/utils/axios';
import showMessage from '@/components/message/message';
import showLoading from '@/components/loading/loading';

export { default as showMessage } from '@/components/message/message';
export { default as showLoading } from '@/components/loading/loading';
export { default as showSelectList } from '@/components/select_list/select_list';
export { showConfirm, showAlert } from '@/components/message_box/message_box';
export { operateLog } from '@/utils/logger';

export function getProjectConfig() {
  // eslint-disable-next-line no-undef
  return CONFIG;
}

// 网站语言相关方法
export function getCurrentLang() {
  // 常规浏览器和IE浏览器的语言
  const lang = navigator.language || navigator.userLanguage;
  return { zh: 0, en: 1 }[lang.slice(0, 2)] ?? 1;
}

export function sessionSaveItem(key, val) {
  sessionStorage.setItem(key, val);
}

export function sessionGetItem(key) {
  return sessionStorage.getItem(key) ?? '';
}

export function pageSaveItem(key, val) {
  try {
    localStorage.setItem(key, val);
  } catch (e) {
    console.log(e);
    alert('当前浏览器不支持localStorage，请关闭隐私/无痕模式，或更换浏览器!');
  }
}

export function pageGetItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.log(e);
    alert('当前浏览器不支持localStorage，请关闭隐私/无痕模式，或更换浏览器!');
    return null;
  }
}

export function pageRemoveItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
    alert('当前浏览器不支持localStorage，请关闭隐私/无痕模式，或更换浏览器!');
  }
}

export function pageRemoveAll() {
  try {
    [
      'token',
      'userInfo',
      'proxyInfo',
      'userSource',
      'langFlag',
      'shareInfo',
    ].forEach(item => {
      pageRemoveItem(item);
    });
  } catch (e) {
    console.log(e);
    alert('当前浏览器不支持localStorage，请关闭隐私/无痕模式，或更换浏览器!');
  }
}

// 设置页面距离顶部的滚动距离
export function setPageScrollTop(num) {
  const target = document.documentElement.scrollTop !== undefined
    ? document.documentElement
    : document.body;
  target.scrollTop = num;
}

export function openNewPage(url) {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = url;
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
}

export function getPlatform() {
  // 没携带值就判断用户所持设备跳转到其系统对应下载模块
  const bowser = window.bowser || {};
  // macOS 系统13及以上 navigator.userAgent 的值与macOS的 navigator.userAgent 相同
  // 暂时通过maxTouchPoints(最同时大触摸点数)进行区分 mac 此值为0 ipad 此值为5
  // (/macOS/gi.test(osname) && window.navigator?.maxTouchPoints > 1)
  // standalone 僅適用於 Apple 的 iOS Safari
  let platform = '';
  if (bowser.windows) platform = 'windows';
  if (bowser.android) platform = 'android';
  if (bowser.ios) platform = 'ios';
  if (bowser.iphone) platform = 'iphone';
  if (bowser.mac) platform = 'mac';
  if (bowser.ipad || (bowser.mac && window.navigator.standalone !== undefined)) {
    platform = 'ipad';
  }
  if (bowser.ipod) platform = 'ipod';
  if (bowser.linux) platform = 'linux';
  return platform;
}

export function isIos() {
  return ['ios', 'iphone', 'ipad'].includes(getPlatform());
}

// 加载Paypal支付脚本 effect
export function initPaypalScript() {
  const paypalScript = document.getElementById('paypalScript');
  if (paypalScript) return true;
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.id = 'paypalScript';
  script.type = 'text/javascript';
  script.src = 'https://www.paypalobjects.com/api/checkout.js';
  head.appendChild(script);
  return new Promise((resolve, reject) => {
    script.onload = () => resolve();
    script.onerror = () => reject();
  });
}

// 距今时间，通过时间戳转换剩余天数和小时
export function getElapsedTime(time) {
  const currDate = new Date().getTime();
  // 时间差毫秒数
  const diffTime = time - currDate;
  if (diffTime < 0) return -1;
  // 相差天数
  const diffDay = Math.floor(diffTime / (24 * 3600 * 1000));
  // 相差小时
  let diffHours = diffTime % (24 * 3600 * 1000);
  diffHours = Math.floor(diffHours / (3600 * 1000));
  let diffMinutes = diffTime - diffDay * 86400000 - diffHours * 3600000;
  diffMinutes = Math.floor(diffMinutes / (60 * 1000));
  return {
      day: diffDay,
      hours: diffHours,
      minutes: diffMinutes,
  };
}

// 获取显示语言对应文本值
export function getLangText(langFlag) {
  const arr = ['zh', 'en', 'zh_tw'];
  return arr[langFlag] || arr[getCurrentLang()] || 'en';
}

// 获取显示语言对应索引值
export function getLangIndex(langText) {
  const o = {
    zh: 0,
    en: 1,
    zh_tw: 2,
    hk: 2,
  };
  return o[langText] ?? getCurrentLang() ?? 1;
}

export function setCustomClientIdByUd() {
  const userInfo = JSON.parse(pageGetItem('userInfo'));
  if (!userInfo) return;
  const {
    email,
    id,
    email_status: emailStatus,
    expired,
    expired_svip: expiredSvip,
    hot,
  } = userInfo;
  openNewPage(`${getProjectConfig().cdn}/quickq/app/kf-web.html?email=${email}&id=${id}&email_status=${emailStatus}&expired=${expired}&expired_svip=${expiredSvip}&hot=${hot}`);
}

export function getLangFlag() {
  const saveLangFlag = Number(pageGetItem('langFlag'));
  return saveLangFlag ? parseInt(saveLangFlag, 10) : getCurrentLang();
}

export function isLogin() {
  const token = pageGetItem('token');
  return token !== null && token !== 'null';
}

export function isAgent() {
  const userSource = parseInt(pageGetItem('userSource'), 10);
  return userSource === 9 || userSource === 10;
}

export function isQuickQS() {
  try {
    return JSON.parse(pageGetItem('userInfo')).hot === '3';
  } catch {
    return false;
  }
}

export function checkIsEmail(email) {
  const reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  const judge = reg.test(email);
  return judge;
}

// 处理用户提交密码
export async function handleUserPwd(pwd) {
  const { sha256 } = (await import(/* webpackChunkName: "js-sha256" */ 'js-sha256')).default;
  return sha256(pwd).toLocaleUpperCase();
}

// 处理对象数组，根据对象属性值进行排序,rlue为0升序、为1降序
export function objSort(obj, attr, rule) {
  obj.sort((a, b) => {
    const value1 = a[attr];
    const value2 = b[attr];
    if (rule === 0) {
      // 从小到大排序
      return value1 - value2;
    }
    // 从大到小排序
    return value2 - value1;
  });
  return obj;
}

function setTimePrefix(time) {
  return time < 10 ? `0${time}` : time;
}

// 剩余时间，通过时间戳转换剩余时、分、秒、毫秒
export function getRemainingHMSM(time) {
  const currDate = Date.now();
  // 时间差毫秒数
  const diffTime = time - currDate;
  if (diffTime > 0) {
    let diffHours = Math.floor(diffTime / (3600 * 1000));
    diffHours = setTimePrefix(diffHours);

    let diffMinutes = diffTime % (3600 * 1000);
    diffMinutes = Math.floor(diffMinutes / (60 * 1000));
    diffMinutes = setTimePrefix(diffMinutes);

    let diffSeconds = diffTime % (60 * 1000);
    diffSeconds = Math.floor(diffSeconds / 1000);
    diffSeconds = setTimePrefix(diffSeconds);

    let diffMillisecond = diffTime % 1000;
    diffMillisecond = Math.floor(diffMillisecond);
    if (diffMillisecond > 100) {
      diffMillisecond = diffMillisecond.toString().slice(0, 2);
    } else if (diffMillisecond < 10) {
      diffMillisecond = setTimePrefix(diffMillisecond);
    }

    return {
      hours: diffHours,
      minutes: diffMinutes,
      seconds: diffSeconds,
      millisecond: diffMillisecond,
    };
  }
  // 传入时间戳比当前时间小
  return -1;
}

export const getDateYMD = time => {
  const dateTime = new Date(time);
  const year = dateTime.getFullYear();
  const month = setTimePrefix(dateTime.getMonth() + 1);
  const date = setTimePrefix(dateTime.getDate());
  return `${year}-${month}-${date}`;
};

// 时间戳日期转换
export const getDateYMDHM = time => {
  const dateTime = new Date(time);
  const ymd = getDateYMD(time);
  const hours = setTimePrefix(dateTime.getHours());
  const minutes = setTimePrefix(dateTime.getMinutes());

  return `${ymd} ${hours}:${minutes}`;
};

// 金额处理，返回字符串类型，统一格式1,000.00
export const handleAmount = num => {
  if (Number.isNaN(num)) return '';
  let n = num.toString();
  // 当数值没小数点
  if (!n.includes('.')) n += '.00';

  const arr = n.split('.');
  const isTwoDigits = arr[1].length === 2;
  const numLen = arr[0].length;

  // 当数值小数点后不是两位数，是一位数
  if (!isTwoDigits) arr[1] = `${arr[1]}0`;

  // 当数值小数点前为三位数以上
  if (numLen > 3) {
    const index = numLen - 3;
    arr[0] = arr[0].split('');
    arr[0].splice(index, 0, ',');
    arr[0] = arr[0].join('');
  }
  n = arr.join('.');

  return n;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getData = createRequest(getProjectConfig());

export const getBase = createRequest({
  baseUrl: getProjectConfig().server,
  timeout: 8000,
  axiosIsEncrypt: '0',
  axiosRetryMax: 1,
});

export const getAliyun = createRequest({
  baseUrl: getProjectConfig().logger,
  timeout: 8000,
  axiosIsEncrypt: '0',
  axiosRetryMax: 1,
  token: 1,
});

export async function request(options, type = 0) {
  const { noShowLoading, ...o } = options;
  const fn = [getData, getBase];
  const langFlag = getLangFlag();
  const lang = getLangText(langFlag);
  const content = getLang()[langFlag];
  const close = noShowLoading ? () => null : showLoading();
  try {
    (!o.method || o.method === 'get') && Object.assign(o.query ?? {}, { lang });
    o.method === 'post' && Object.assign(o.data ?? {}, { lang });
    const res = await fn[type](o);
    const { err_code: errCode, err_msg: errMsg } = res.data;
    if (errCode === 0) return [true, res];
    const resultMsg = content.errCodeMsg[errCode] || errMsg;
    resultMsg && await showMessage(resultMsg);
    if (errCode === 1003) {
      pageRemoveAll();
      window.location.href = '/login';
    }
    return [false, res];
  } catch (error) {
    console.log(error);
    return [false, error];
  } finally {
    close();
  }
}

export function isUserAgentHasTarget(str) {
  return navigator.userAgent.toLowerCase().includes(str);
}

export function isCanDownloadBrowser() {
  // 微信/qq浏览器(手机设备)
  if (isUserAgentHasTarget('nettype')) return false;
  // 微博浏览器
  if (isUserAgentHasTarget('weibo') && isIos()) return false;
  return true;
}

export function showOpenOthetBrowserTips() {
  const { openInBro } = getProjectConfig();
  const link = isIos() ? openInBro.ios : openInBro.android;
  document.body.innerHTML = `<img src="${link}" style="position: fixed; width: 100vw; z-index: 9999" />`;
  document.body.style = 'background-color: #ffffff';
}
